import { 
  Outlet
} from "react-router-dom";

//Helpers
import ScrollToTop from "../helpers/ScrollToTop";
import Nav from "../components/Nav";
import Sidebar from "../components/Sidebar";

export default function Root() {

  return (
    <div className="text-black bg-white min-h-screen selection:bg-cape-honey selection:text-black max-w-screen-2xl mx-auto">
      <ScrollToTop />
      <div className='flex flex-col gap-16'>
        <div className="flex flex-row items-start">
          <Sidebar />
          <div className="w-full">
              <Nav />
              <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}