import data from '../projects.json';
import { useParams } from "react-router-dom";
import Footer from '../components/Footer'

function Section({section}) {
    return (
        <div className="flex flex-col md:flex-row mb-12 md:mb-24 gap-6">
            <h2 className="text-base font-monospace min-w-[200px] leading-none mb-3 md:mb-0">{section.title}</h2>
            <div className='flex flex-col gap-6'>
                {section.content.map((block) => {
                    if (block.type === "text") {
                        return (
                            <p className="font-sans leading-relaxed max-w-2xl">{block.text}</p>
                        )
                    } else if (block.type === "team") {
                        return (
                            <ul>
                                {block.team.map((teammate) => (
                                    <li><a href={teammate.link} target="_blank" rel="noreferrer" className='underline hover:text-green transition-all duration-200 ease-in-out'>{teammate.name}</a> · {teammate.role}</li>
                                ))}
                            </ul>
                        )
                    } else if (block.type === "img") {
                        return (
                            <div className='my-6'>
                                <img src={block.img} alt={block.caption} />
                                <p className="text-sm font-monospace leading-normal mt-3">{block.caption}</p>
                            </div>
                        )
                    } else if (block.type === "video") {
                        return (
                            <div className="mb-6 relative h-0 pb-[56.25%]">
                                <iframe src={block.link} title={block.title} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen className='absolute t-0 l-0 w-full h-full'></iframe>
                            </div>
                        )
                    } else {
                        return(
                            <div></div>
                        )
                    }
                })}
            </div>
        </div>
    )
}

function Metadata({project}){
    if (project.link) {
        return(
            <p className="font-monospace text-base leading-normal">{project.year} · <a href={project.link} target="_blank" rel="noreferrer" className="underline hover:text-green transition-all duration-200 ease-in-out">{project.company}</a> · {project.group}</p>
        )
    } else {
        return (
            <p className="font-monospace text-base leading-normal">{project.year} · {project.company} · {project.group}</p>
        )
    }
}

export default function Project() {
    // load project
    const { projectId } = useParams();
    const loadedData = JSON.stringify(data);
    const projects = JSON.parse(loadedData);
    const project = projects.find(p => p.id === projectId );
    const sections = project.sections;

    return (
        <div>
            <div className='p-6'>
                <div className='mb-12 md:mb-24'>
                    <h1 className='text-5xl md:text-6xl leading-tight text-black font-medium font-sans mt-12 md:mt-24 md:mb-3'>{project.name}</h1>
                    <Metadata project={project} />
                </div>
                {sections.map((section) => (
                    <Section section={section} />
                ))}
            </div>
            <Footer projects={projects} currentProject={project}  />
        </div>
    )
}