import React from 'react';
import ReactDOM from 'react-dom/client';
import { 
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';

//Routes
import Root from "./routes/root";
import ErrorPage from "./error-page";
import Projects from "./routes/projects";
import About from "./routes/about";
import Project from "./routes/project";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Projects /> },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "projects/:projectId",
        element: <Project />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// script.js
const scrollDiv = document.querySelector('.scroll-div');

window.addEventListener('scroll', () => {
    const scrollY = window.scrollY;

    // Adjust the Y position of scrollDiv as per your requirements
    // For example, you can make it scroll faster by multiplying scrollY with a higher value
    scrollDiv.style.transform = `translate(-50%, ${scrollY}px)`;
    console.log(scrollY)
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
