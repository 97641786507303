import * as React from "react";
import { NavLink } from "react-router-dom";

function NavItem({destination, route}) {
    const navClasses = "font-sans uppercase text-sm rounded tracking-widest px-3 py-1 bg-grey-1 hover:cursor-pointer transition-all duration-200 ease-in-out leading-none hover:bg-cape-honey"
    const activeNavClasses = " bg-green hover:bg-green"

    return(
        <li className="flex">
            <NavLink
                className={function ({ isActive }) {
                    return isActive ? navClasses + activeNavClasses : navClasses;
                }
                }
                to={route}
            >
                {destination}
            </NavLink>
        </li> 
    )
}

export default function Nav() {
    return (
        <nav className="flex flex-row w-full justify-center gap-3 sticky top-3 z-10">
            <NavItem destination={"Work"} route={"/"} />
            <NavItem destination={"About"} route={"/about"} />
        </nav>
    )
}