import data from '../projects.json';
import ProjectCard from '../components/ProjectCard';

export default function Index() {
    const loadedData = JSON.stringify(data);
    const projects = JSON.parse(loadedData);

    return (
        <div className='p-6 flex flex-col gap-6'>
            <h1 className='text-5xl md:text-6xl leading-tight text-black font-medium font-sans my-12 md:my-24'>Let's go off-trail and design solutions that support a brighter future.</h1>
            <ul className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-12 md:gap-y-24">
                {projects
                .filter((project) => project.isFeatured)
                .sort((a, b) => b.year - a.year) // Sort by year in descending order
                .map((project, index) => (
                    <ProjectCard 
                        key={project.id}
                        id={project.id}    
                        name={project.name}
                        company={project.company}
                        year={project.year}
                        img={project.img}
                        scrollSpeed={index % 3 === 1 ? 4 : 6}
                    />
                ))}
            </ul>
        </div>
    )
}