import * as React from "react";
import data from '../projects.json';
import { Link, NavLink } from "react-router-dom";

export function SidebarGroup({ projects, group }) {
    const navClasses = "font-monospace text-base leading-5 hover:bg-cape-honey transition-all duration-200 ease-in-out py-1 hover:cursor-pointer block";
    const activeNavClasses = "bg-green hover:bg-green"; // Define active class styles if any

    return (
        <div className="flex flex-col gap-6">
            <h3 className="font-serif text-2xl pb-1 border-b border-black">{group}</h3>
            <ul className="flex flex-col gap-1">
                {projects
                    .filter(project => project.group === group)
                    .sort((a, b) => b.year - a.year) // Sort by year in descending order
                    .map(project => (
                        project.isActive ? 
                            <li key={project.id}>
                                <a href={project.link} target="_blank" rel="noreferrer" className="flex flex-row gap-2 items-center font-monospace text-base leading-5 hover:bg-cape-honey transition-all duration-200 ease-in-out py-1 hover:cursor-pointer block">
                                    {project.company}
                                    <div className="ring-container">
                                        <div className="ringring"></div>
                                        <div className="circle"></div>
                                    </div>
                                </a>
                            </li>
                            :
                            <li key={project.id}>
                                <NavLink 
                                    to={`projects/${project.id}`} 
                                    className={({ isActive }) => 
                                        isActive ? navClasses + " " + activeNavClasses : navClasses
                                    }>
                                    {project.company}
                                </NavLink>
                            </li>
                    ))    
                }
            </ul>
        </div>
    );
}



export default function Sidebar() {
    const loadedData = JSON.stringify(data);
    const projects = JSON.parse(loadedData);

    return (
        <div className='hidden md:flex sticky flex-col gap-6 top-0 flex-none w-60 py-6 pl-6 pr-2 border-r border-black min-h-screen overflow-y-scroll max-h-screen no-scrollbar'>
            <div className="flex flex-col gap-2">
                <Link to="/">
                    <h2 className="font-display text-4xl leading-none whitespace-nowrap">Off-Trail</h2>
                </Link>
                <p className='font-monospace text-sm whitespace-nowrap leading-5'>with Robinson Greig</p>
            </div>
            <SidebarGroup projects={projects} group="Consulting" />
            <SidebarGroup projects={projects} group="Work" />
            <SidebarGroup projects={projects} group="Personal" />
            <p className="font-sans text-xs text-secondary-text mt-6">Off-Trail Studio LLC</p>
        </div>
    )
}