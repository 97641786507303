import { Link } from "react-router-dom";

export default function Footer({projects, currentProject}) {
    let nextProject;

    // function to find next proejct of ANY type
    function findNextProject() {
        const currentProjectIndex = projects.findIndex((p) => p.id === currentProject.id)

        if (currentProjectIndex === projects.length -1) {
            nextProject = projects[0];
        } else {
            nextProject = projects[currentProjectIndex + 1];
        }
    }

    findNextProject();

    return (
        <div className="flex leading-none border-t-[1px] border-black font-monospace">
            <p className="border-r-[1px] border-black bg-white py-8 px-4 md:px-12">Up Next</p>
            <Link to={`/projects/${nextProject.id}`} className="py-8 px-3 md:px-12 bg-white flex-1 text-center whitespace-nowrap overflow-hidden text-ellipsis hover:cursor-pointer hover:bg-cape-honey transition-colors duration-200 ease-in-out">{nextProject.name}</Link>
        </div>
    )
}