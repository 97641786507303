import React from 'react';
import { Link } from "react-router-dom";

const ProjectCard = ({id, name, company, year, img}) => {
 
  return (
    <li>
        <Link className="flex flex-col justify-between rounded-xl overflow-hidden transition-colors duration-300 ease-in-out shimmer-link-container scroll-div h-full" to={`projects/${id}`}>
            <img src={img} alt={name} className="object-cover object-top aspect-[2/1] rounded-b-xl"></img>
            <div className="flex flex-col gap-1 h-full mt-4">
                <p className="font-sans text-base font-medium leading-5">{company}</p>
                <h2 className="font-monospace text-sm leading-5">{year} · {name}</h2>
            </div>
        </Link>
    </li>
  );
};

export default ProjectCard;
