export default function About() {
    return (
        <div className='p-6 flex flex-col gap-6'>
            <h1 className='text-5xl md:text-6xl leading-tight text-black font-medium font-sans my-12 md:my-24'>About Me</h1>
            <div className="flex flex-col md:flex-row gap-6">
                <img src="/img/robinson-headshot.jpeg" alt="Robinson Greig" className="rounded-xl md:rounded-3xl w-60 h-60"/>
                <div className="flex flex-col gap-6 font-sans items-start max-w-2xl">
                    <p>I'm a mission-driven designer and product generalist with experience building and scaling B2B and B2C products. I started my career in a product and operations role at a seed-stage health and wellness startup called Ten Percent Happier. Since then, I've contracted for a number of seed- and growth-stage startups in assorted product, design, frontend, and operations roles.</p>
                    <p>I'm particularly interested in contributing to products that support a more sustainable future, help small businesses thrive, and unlock new ways to learn and work.</p>
                    <p>Connect with me on <a href="https://www.linkedin.com/in/robinsongreig/" target="_blank" rel="noreferrer" className="underline hover:text-green transition-all duration-200 ease-in-out">LinkedIn</a>.</p>
                    <a href="https://cal.com/robinsongreig/intro-call" target="_blank" rel="noreferrer" className='bg-cape-honey text-black rounded p-6 leading-none hover:rounded-xl transition-all duration-300 ease-in-out font-monospace'>Let's chat!</a>
                </div>
            </div>
        </div>
    )
}