import { useRouteError } from "react-router-dom";
import Nav from './components/Nav'

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <Nav />
      <div>
          <div className='max-w-3xl mx-auto p-6 md:pt-24'>
            <h1 className='text-5xl font-monospace mb-3'>Shoot, something went wrong!</h1>
            <p className="bg-pink text-white p-3 font-sans text-regular mt-6">
              Error: <i>{error.statusText || error.message}</i>
            </p>
          </div>
      </div>
    </div>
  );
}